import {Node} from 'slate';

export default class Article {

    id: string = "";
    slug: string = "";
    title: string = "";
    body: Node[] = [{type: 'paragraph', children: [{ text: '' }]}];
    segment: string = "";
    segmentSlug: string = "";
    category: string = "";
    categorySlug: string = "";
    section: string = "";
    sectionSlug: string = "";
    updateAt: Date | null = null;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? null;
        this.slug = data?.slug ?? "";
        this.title = data?.title ?? "";
        this.body = data?.body ?? [{type: 'paragraph', children: [{ text: '' }]}];
        this.segment = data?.segment ?? "";
        this.segmentSlug = data?.segmentSlug ?? "";
        this.category = data?.category ?? "";
        this.categorySlug = data?.categorySlug ?? "";
        this.section = data?.section ?? "";
        this.sectionSlug = data?.sectionSlug ?? "";
        this.updateAt = data?.updateAt ? (typeof data?.updateAt === "string" ? new Date(data.updateAt) : (data.updateAt instanceof Date ? data.updateAt : new Date(data.updateAt.toDate().toString()))) : null;
    }

    toJson(): Article {
        let bd = this.body.map((a) => { return Object.assign({}, a) });
        let data = Object.assign({}, this);
        data.body = bd;
        return data;
    }

}